import React from "react";
import "./style.css";
import "../../App.css";
import {
  butterfly_format,
  call,
  email,
  facebook,
  instagram,
  logo,
  logoSmall,
  postcard,
  twitter,
  website,
} from "../../assets";

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="logo-col">
            <div
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width:"4rem",
                height:"4rem",
                margin:"0 auto"
              }}
            >
              <img
                src={butterfly_format}
                alt="logoSmall"
                style={{ height: "3.1rem", width: "3.1rem", }}
              />
            </div>

            <a href="#" className="footer-logo">
              GLEAFINK PVT LTD
            </a>

            <ul className="social-links">
              <li>
                <a className="footer-link" href="#">
                  <img
                    src={instagram}
                    alt="instagram icon"
                    className="social-icon"
                  />
                </a>
              </li>
              <li>
                <a className="footer-link" href="#">
                  <img
                    src={facebook}
                    alt="facebook icon"
                    className="social-icon"
                  />
                </a>
              </li>
              <li>
                <a className="footer-link" href="#">
                  <img
                    src={twitter}
                    alt="twitter icon"
                    className="social-icon"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="address-col" id="Connect">
            <p className="footer-heading">Contact Us:</p>
            <address className="contacts">
              <p className="address">
                <img
                  src={postcard}
                  alt="Postcard icon"
                  className="footer-icons"
                />
                <div>
                  #16, AsherKhanna St., Near Lavanya Theatre, Sivan Chetty
                  Garden, Bangalore, KA - 560 042
                </div>
              </p>
              <p>
                <a className="footer-link" href="tel:+91 99000 94377">
                  <img src={call} alt="Call icon" className="footer-icons" />
                  <div>+91 99000 94377</div>
                </a>

                <a className="footer-link" href="mailto:knockers@gleafink.com">
                  <img src={email} alt="Email icon" className="footer-icons" />
                  <div>knockers@gleafink.com</div>
                </a>

                <a
                  className="footer-link"
                  href="www.gleafink.com"
                  target="_blank"
                >
                  <img
                    src={website}
                    alt="Website icon"
                    className="footer-icons"
                  />
                  <div>www.gleafink.com</div>
                </a>
              </p>
            </address>
          </div>
          <div className="license-col">
            <div>
              <p className="footer-heading">Others</p>
              <a href="#Careers">Careers</a>
              <a className="footer-link" href="#" target="_blank">
                Legal
              </a>
            </div>
            <br />
            <div>
              <p>CIN - U37200KA2020PTC135366</p>
              <p>GSTIN - 29AAICG3609C1ZR</p>
            </div>
          </div>
        </div>
        <div className="copyright">
          Copyright © {year} Gleafink Pvt Ltd. All rights reserved.
        </div>
      </footer>
    </>
  );
};

export default Footer;
