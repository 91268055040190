import React from "react";
import "./style.css";
import "../../App.css";

const About = () => {
  return (
    <>
      <h1 className="abt-h1" id="About">
        About
      </h1>
      <div className="abt">
        <div className="abt-content">
          <div className="abt-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="abt-container">
              <h3>Our Passion Story</h3>
              <p>
                An Idea can just not change the world but an idea that is noble
                can ensure it is a positive change. GleafInk, is the fruit of
                many years of ideating the right product to be developed, that
                can enhance business and transform the way things are done in
                the future. With a humble background of bringing science & work
                experience in the IT domain and collaborating with bright minds,
                we are proud to be ‘GleafInk’ today. We want the customers to
                leave an indelible impression in their areas of expertise,
                through our solutions, that we have meticulously and
                passionately developed. We have every day, put ourselves in the
                shoes of our customers and have traversed the path to ensure
                that our solutions are not only the right fit but are
                sustainable and future proof.
              </p>
            </div>
          </div>
        </div>
        <div className="abt-content-flex">
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Diverse Team</h3>
                <p>
                  Our team of professionals come from a variety of backgrounds
                  and experiences.
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Innovative Technology</h3>
                <p>
                  We stay up-to-date with the latest technology trends and
                  explore innovative solutions that can transform your business
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Creative Problem-Solving</h3>
                <p>
                  We use a collaborative approach to problem-solving and take
                  the time to understand business objectives
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Client Focused</h3>
                <p>
                  Our focus is on building long-term relationships with our
                  clients, providing exceptional services, and delivering
                  results that exceed expectations.
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
        </div>
        <div className="abt-content-flex">
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Customized Solution</h3>
                <p>
                  We take time to understand our clients' business needs and
                  tailor solutions to maximize value.
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Attention to Detail</h3>
                <p>
                  We listen with our hearts keenly to understand the details. As
                  every minute detail is as important, as the bigger details. We
                  leave no stone unturned to ensure we deliver what we have
                  promised
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="abt-content">
            <div className="abt-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="abt-container">
                <h3>Actionable Insights</h3>
                <p>
                  Our goal is not only to provide information but also to help
                  businesses implement changes that drive measurable results
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="abt-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
