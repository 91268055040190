import React from "react";
import "./style.css";
import "../../App.css";

const WeValue = () => {
  return (
    <div className="weval" id="WeValue">
      <div className="weval-content">
        <h1>We Value:</h1>
        <div className="weval-points">
          <p>
            Our ‘Client at the Center’, enables us to put your needs ahead of
            ours and this propels us to build actionable insights that will
            enhance business and provide you a competitive edge. Our more than 5
            years in ‘Data Insight Sciences’ has made us sharper in
            understanding the need of tomorrow and making technology future
            ready.
          </p>
          <p>
            Our ‘We Win Together’, faith ensures, we collaborate as a cohesive
            team, with similar ideology that everyone who comes to us, should
            also be a winner and is part of our bigger team. Our Team comprises
            industry-leading data professionals who are passionate about driving
            change
          </p>
          <p>
            Our ‘We create Smarter futures’, we believe data should be
            accessible and available to everyone in any company. We strive to
            create a future where every company big or small can leverage the
            power of data to stay ahead of the competition. Join us on this
            journey to smarter & brighter avenues.
          </p>
        </div>
      </div>

      {/* <div className="core-img">
        <div className="rhombus">
          <img src="" alt="home-image" />
          </div>
      </div>
      <div className="rhombus2"></div> */}
    </div>
  );
};

export default WeValue;
