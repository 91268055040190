import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../../../../App.css";
import { logo } from "../../../../assets";

const NavbarMetainsights = () => {
  const navigate = useNavigate();

  return (
    <div className="nav-prod-container">
      <div className="nav-first-box">
        <div className="logo-prod" style={{ color: "#1890ff" }}>
          Metainsights
        </div>

        <nav className="nav-prod">
          <a href="/" className="active">
            Solutions
          </a>
          <a href="/#">Product</a>
          {/* <div className="dropdown-prod">
          <button className="dropbtn-prod">Products</button>
          <div className="dropdown-content-prod">
            <a href="/Metainsights" target="_blank">
              Metainsights
            </a>
            <a href="/Metamorph" target="_blank">
              Metamorph
            </a>
          </div>
        </div> */}
          <a href="#">Resources</a>
          <a href="/#">Pricing</a>
        </nav>
      </div>
      <div className="prod-btn-line">
        <p className="p-btn" style={{ margin: "15px 0 15px" }}>
          Login
        </p>
        <button
          className="nav-prod-btn"
          onClick={() => {
            window.open("/Connect", "_blank");
          }}
        >
          Book a Demo
        </button>
      </div>
    </div>
  );
};

export default NavbarMetainsights;
