import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../../../App.css";
import { logo } from "../../../assets";
// import { useWindowDimensions } from "../Common/useWindowDimensions";

const NavbarDataEnggAna = () => {
  const navigate = useNavigate();
  // const { height, width } = useWindowDimensions();

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShow(false);
      } else {
        // if scroll up show the navbar
        setShow(true);
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  // Smooth scrolling animation
  // const allLinks = document.querySelectorAll("a:link");
  // console.log(allLinks);
  // allLinks.forEach((link) => {
  //   link.addEventListener("click", (e) => {
  //     e.preventDefault();
  //     const href = link.getAttribute("href");
  //     console.log(href);

  //     // scroll back to top
  //     if (href == "#")
  //       window.scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //       });
  //     // scroll to other links
  //     if (href !== "#" && href.startsWith("#")) {
  //       const sectionEl = document.querySelector(href);
  //       sectionEl.scrollIntoView({ behavior: "smooth" });
  //     }
  //   });
  // });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }

    return () => {
      window.removeEventListener("resize", updateDimension);
      console.log("width", screenSize.width);
    };
  }, [screenSize, lastScrollY]);

  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <>
      {show && (
        <div className="nav-container-DataEnggAna">
          <img
            src={logo}
            alt={"Logo"}
            className="logo-DataEnggAna"
            onClick={() => {
              navigate("/");
            }}
          />
          {screenSize.width > 385 && (
            <nav className="nav-DataEnggAna">
              <a
                href="/"
                className="active"
                // onClick={(e) => {
                //   let home = document.getElementById("Home");
                //   e.preventDefault();
                //   home &&
                //     home.scrollIntoView({ behavior: "smooth", block: "start" });
                //   window.history.pushState("home", "Home", "/Home");
                // }}
              >
                Home
              </a>
              <a
                href="/#About"
                // onClick={(e) => {
                //   let about = document.getElementById("About");
                //   e.preventDefault();
                //   about &&
                //     about.scrollIntoView({
                //       behavior: "smooth",
                //       block: "start",
                //     });
                //   window.history.pushState("about", "About", "/About");
                // }}
              >
                About
              </a>
              <div className="dropdown-DataEnggAna">
                <a
                  className="dropbtn-DataEnggAna"
                  href="/#Services"
                  // onClick={(e) => {
                  //   let services = document.getElementById("Services");
                  //   e.preventDefault();
                  //   services &&
                  //     services.scrollIntoView({
                  //       behavior: "smooth",
                  //       block: "start",
                  //     });
                  //   window.history.pushState(
                  //     "services",
                  //     "Services",
                  //     "/Services"
                  //   );
                  // }}
                >
                  Services
                </a>
                <div className="dropdown-content-serv-DataEnggAna">
                  <a href="/#Services" target="_blank">
                    Data Strategy & Roadmap
                  </a>
                  <a href="/DataEnggAna" target="_blank">
                    Data & Analytics Modernization
                  </a>
                  <a href="/#Services" target="_blank">
                    Data Engineering
                  </a>
                  <a href="/#Services" target="_blank">
                    Machine Learning & AI
                  </a>
                  <a href="/#Services" target="_blank">
                    Data Monetization
                  </a>
                </div>
              </div>
              <div className="dropdown-DataEnggAna">
                <a
                  className="dropbtn-DataEnggAna"
                  href="/#Products"
                  // onClick={(e) => {
                  //   let products = document.getElementById("Products");
                  //   e.preventDefault();
                  //   products &&
                  //     products.scrollIntoView({
                  //       behavior: "smooth",
                  //       block: "start",
                  //     });
                  //   window.history.pushState(
                  //     "products",
                  //     "Products",
                  //     "/Products"
                  //   );
                  // }}
                >
                  Products
                </a>
                <div className="dropdown-content-DataEnggAna">
                  <a href="/Metainsights" target="_blank">
                    Metainsights
                  </a>
                  <a href="/Metamorph" target="_blank">
                    Metamorph
                  </a>
                </div>
              </div>
              <a
                href="/#WeValue"
                // onClick={(e) => {
                //   let weValue = document.getElementById("WeValue");
                //   e.preventDefault();
                //   weValue &&
                //     weValue.scrollIntoView({
                //       behavior: "smooth",
                //       block: "start",
                //     });
                //   window.history.pushState("weValue", "WeValue", "/WeValue");
                // }}
              >
                Why Gleafink
              </a>
              <a href="/#Labs">Gleafink Labs</a>
              <a href="/#Insights">Insights</a>
              <a href="/#Connect" target="_blank">
                Lets Connect
              </a>
            </nav>
          )}

          {screenSize.width <= 385 && <nav className="nav-DataEnggAna"></nav>}
        </div>
      )}
    </>
  );
};

export default NavbarDataEnggAna;
