import React from "react";
import "./style.css";
import "../../../../App.css";
import {
  call,
  email,
  facebook,
  instagram,
  logo,
  postcard,
  twitter,
  website,
} from "../../../../assets";

const FooterMetainsights = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <>
      <footer className="prod-footer">
        <div className="prod-footer-container">
          <div className="prod-logo-col">
            <a href="#" className="prod-footer-logo">
              GLEAFINK PVT LTD
            </a>
          </div>
          <div>
            <ul className="prod-social-links">
              <li>
                <a className="prod-footer-link" href="#">
                  <img
                    src={instagram}
                    alt="instagram icon"
                    className="prod-social-icon"
                  />
                </a>
              </li>
              <li>
                <a className="prod-footer-link" href="#">
                  <img
                    src={facebook}
                    alt="facebook icon"
                    className="prod-social-icon"
                  />
                </a>
              </li>
              <li>
                <a className="prod-footer-link" href="#">
                  <img
                    src={twitter}
                    alt="twitter icon"
                    className="prod-social-icon"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="prod-license-col">
            <a className="prod-footer-link" href="#">
              Terms
            </a>
            <a className="prod-footer-link" href="#">
              Privacy
            </a>
            <a className="prod-footer-link" href="#">
              Security
            </a>

            <div className="prod-copyright">© {year} Gleafink Pvt Ltd.</div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterMetainsights;
