import React from "react";
import "./style.css";
import "../../App.css";
import Navbar from "../Navbar";
import Footer from "../Footer";

import VisionMisson from "../VisionMission";
import CoreValues from "../CoreValues";
import Services from "../Services";
import Products from "../Products";
import {
  pointer,
  UnderConstructionImage,
  websiteimg1,
  websiteimg2,
  websiteimg3,
} from "../../assets";
import Testimonials from "../Testimonials";
import Featured from "../Featured";
import WeValue from "../WeValue";
import About from "../About";

const UnderConstruction = () => {
  return (
    <>
      <Navbar />
      <div className="UnderConst" id="UnderConst">
        <div className="construct">
          <div className="construct-content">
            <h1>This Page is Under Construction</h1>
            <p>
              <p>
                Our team is working on the design and development of this page,
                thank you for your patience and understanding
              </p>
            </p>
          </div>
          <img
            src={UnderConstructionImage}
            alt="under construction"
            style={{
              width: "68%",
              height: "80vh",
              padding:"2rem",
              // boxShadow: "0 0 18px 18px white inset",
              backgroundColor: "#E6F4FF",
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UnderConstruction;
