import React from "react";
import "./style.css";
import "../../App.css";
import NavbarDataEnggAna from "./NavbarDataEnggAna";
import FooterDataEnggAna from "./FooterDataEnggAna";
import {
  DataEnggAna1,
  DataEnggAna10,
  DataEnggAna12,
  DataEnggAna4,
  DataEnggAna5,
  DataEnggAna6,
  DataEnggAna7,
  DataEnggAna8,
} from "../../assets";

const DataEnggAna = () => {
  return (
    <>
      <NavbarDataEnggAna />
      <div className="dea">
        {/* <h1 className="dea-h1">Services</h1> */}
        <div className="dea-content">
          <h3>Services</h3>
          <h1>Data Engineering & Analytics Modernization</h1>
          <p>
            <p>
              A leading data & advanced analytics company. We empower businesses
              to make data-driven decisions for a smarter future.
            </p>
          </p>

          <p>
            <h3>Data Engineering & Analytics</h3>
            <p>
              Enhance your business models and boost your digital economy by
              harnessing cutting-edge data engineering software built on
              cloud-based technologies. Successive Cloud harnesses the full
              spectrum of contemporary data technologies through cloud-based
              services, fostering increased confidence in data and offering
              sophisticated automation for data pipeline management. Our
              next-generation data and analytics capabilities are enriched with
              profound domain knowledge and a comprehensive grasp of technology.
            </p>
            <p>
              We empower you to harness the immense potential of advanced data
              analytics, extracting actionable insights through the application
              of data science, artificial intelligence (AI), and machine
              learning (ML) technologies."
            </p>
          </p>
          {/* <div className="dea-card">
            <div className="dea-container">
              <h3>Our Passion Story</h3>
              <p>
                An Idea can just not change the world but an idea that is noble
                can ensure it is a positive change. GleafInk, is the fruit of
                many years of ideating the right product to be developed, that
                can enhance business and transform the way things are done in
                the future. With a humble background of bringing science & work
                experience in the IT domain and collaborating with bright minds,
                we are proud to be ‘GleafInk’ today. We want the customers to
                leave an indelible impression in their areas of expertise,
                through our solutions, that we have meticulously and
                passionately developed. We have every day, put ourselves in the
                shoes of our customers and have traversed the path to ensure
                that our solutions are not only the right fit but are
                sustainable and future proof.
              </p>
            </div>
          </div> */}
        </div>
        <div className="dea-content-flex">
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img check"
                src={DataEnggAna10}
                alt="DataEnggAna10"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Modernize</h3>
                <p>
                  Modernize your data infrastructure and move your data to
                  cloud.
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna5}
                alt="DataEnggAna5"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Liberate</h3>
                <p>Liberate data using data lake and breakdown data silos.</p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna6}
                alt="DataEnggAna6"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Data Driven</h3>
                <p>Make data-driven decision by unlocking valuable insights.</p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna7}
                alt="DataEnggAna7"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>AI and ML</h3>
                <p>
                  Harness the true power of data and innovate using AI & ML.
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
        </div>
        <div className="dea-content">
          <h3>Our data engineering and analytics services</h3>
          <p>
            "Gleafink proficiency in data engineering and analytics ensures a
            streamlined and rapid creation of a top-tier data ecosystem. Our
            solution delivers immediate benefits to your business operations and
            steers you towards becoming industry leaders who make data-driven
            decisions."
          </p>
        </div>
        <div className="dea-content-grid">
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna4}
                alt="DataEnggAna4"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Data Strategy</h3>
                <p>
                  Improve your insightful business decisions and operations with
                  our broad data analytics strategy.
                  <p>Data migration advisory</p>
                  <p>Data modernization advisory</p>
                  <p>Data governance</p>
                  <p>Prepare implementation roadmap</p>
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna8}
                alt="DataEnggAna8"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Enterprise Data Platform</h3>
                <p>
                  Guarantee enterprise-level reliability as you optimize your
                  data infrastructure through an extensive array of data
                  analytics consulting services.
                  <p>Harnessing Big Data in the Cloud</p>
                  <p>Embracing Cloud-Native Data Engineering</p>
                  <p>Implementing DataOps and MLOps</p>
                  <p>Cultivating Capabilities</p>
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna12}
                alt="DataEnggAna12"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Data Engineering Services</h3>
                <p>
                  Develop cutting-edge automated data pipelines, capitalize on
                  your data structures, and establish advanced data workflows
                  through modernizing data analytics.
                  <p>Identifying and Ingesting Data Sources</p>
                  <p>Managing Data Lakes and Data Warehousing</p>
                  <p>Enhancing Reporting and Security Measures</p>
                  <p>Designing and Deploying Data Pipelines</p>
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
          <div className="dea-content">
            <div className="dea-card">
              <img
                className="dea-card-img"
                src={DataEnggAna1}
                alt="DataEnggAna1"
                // style={{ width: "100%" }}
              />
              <div className="dea-container">
                <h3>Data Science & Machine Learning</h3>
                <p>
                  Minimize revenue loss and enhance customer satisfaction
                  through the application of our statistical models and
                  algorithms.
                  <p>Utilize BI dashboards and machine learning</p>
                  <p>Leverage data intelligence and visualization</p>
                  <p>Employ data analytics techniques</p>
                  <p>Implement real-time monitoring and alerts</p>
                </p>
              </div>
              {/* <Link to="/Metainsights">Learn More</Link> */}
              {/* <p
                className="dea-btn"
                onClick={() => {
                  // navigate("/Metainsights");
                  // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
        </div>
        <div className="dea-content">
          <h3>Our Data Engineering and Analytics Approach </h3>
          <p>
            We process, transform, utilize, and expose exponential growth of
            data to create modern data architecture.
          </p>
        </div>
      </div>
      <FooterDataEnggAna />
    </>
  );
};

export default DataEnggAna;
