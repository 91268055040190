import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../../App.css";
import {
  add,
  donut,
  download,
  expandCirlce,
  facebook,
  help,
  home,
  instagram,
  logoSmall,
  more,
  schedule,
  search,
  stack,
  twitter,
} from "../../assets";

const Dashboard = () => {
  const [greet, setGreet] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [expand, setExpand] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();

  useEffect(() => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      // console.log("good morning");
      setGreet("Good Morning");
    } else if (curHr < 18) {
      // console.log("good afternoon");
      setGreet("Good Afternoon");
    } else {
      // console.log("good evening");
      setGreet("Good Evening");
    }
  });

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  return (
    <>
      <div className="dashboard">
        <div className={expand ? "nav-content expand" : "nav-content"}>
          <div className="nav-opt">
            <img className="dash-logo" src={logoSmall} alt="logo_small" />
            <div className="nav-ele">
              <img src={home} alt="home icon" className="nav-icon" />
              {expand && <p>Home</p>}
            </div>
            <div className="nav-ele">
              <img src={add} alt="add icon" className="nav-icon" />
              {expand && <p>Add</p>}
            </div>
            <div className="nav-ele">
              <img src={donut} alt="donut icon" className="nav-icon" />
              {expand && <p>Tasks</p>}
            </div>
            <div className="nav-ele">
              <img src={schedule} alt="schedule icon" className="nav-icon" />
              {expand && <p>Logs</p>}
            </div>
            <div className="nav-ele">
              <img src={download} alt="download icon" className="nav-icon" />
              {expand && <p>Download</p>}
            </div>
          </div>
          <div className="aux-opt">
            <div className="nav-ele">
              <img src={help} alt="help icon" className="nav-icon" />
              {expand && <p>Help</p>}
            </div>
            <div className="nav-ele">
              <img src={more} alt="more icon" className="nav-icon" />
              {expand && <p>More</p>}
            </div>
            <div className="nav-ele" onClick={() => setExpand(!expand)}>
              <img
                src={expandCirlce}
                alt="more icon"
                className={expand ? "nav-icon lefter" : "nav-icon righter"}
              />
              {expand && <p>Collapse</p>}
            </div>
          </div>
        </div>
        <div className="dash-content">
          <div className="header">
            <h3>{greet} User</h3>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                console.log("Your Search:", searchValue);
                setSearchValue("");
              }}
            >
              <input
                type="text"
                placeholder="Search.."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                // onKeyDown={(event) => {
                //   if (event.key === "Enter") {
                //     console.log("Your Search:", searchValue);
                //   }
                // }}
              />
              <img
                src={search}
                alt="search icon"
                className="search-icons"
                onClick={() => {
                  console.log("Your Search:", searchValue);
                  setSearchValue("");
                }}
              />
            </form>
          </div>
          <div className="pie-cards">
            <div className="dash-pie-card">
              <h3>Pie One</h3>
              <div class="pie hollow">
                <span>12</span>
              </div>
            </div>
            <div className="dash-pie-card">
              <h3>Pie Two</h3>
              <div class="pie hollow">
                <span>12</span>
              </div>
            </div>
            <div className="dash-pie-card">
              <h3>Pie Three</h3>
              <div class="pie hollow">
                <span>12</span>
              </div>
            </div>
          </div>
          <div className="main-cards">
            <div className="dash-card">
              {/* <div className="dash-container"> */}
              <img src={stack} alt="stack icon" className="card-img" />
              <p>Batch Ingetion</p>
              {/* </div> */}
            </div>
            <div className="dash-card">
              {/* <div className="dash-container"> */}
              <img src={stack} alt="stack icon" className="card-img" />
              <p>Streaming Ingetion</p>
              {/* </div> */}
            </div>
            <div className="dash-card">
              {/* <div className="dash-container"> */}
              <img src={stack} alt="stack icon" className="card-img" />
              <p>Streaming Sync</p>
              {/* </div> */}
            </div>
            <div className="dash-card">
              {/* <div className="dash-container"> */}
              <img src={stack} alt="stack icon" className="card-img" />
              <p>Mart Builder</p>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
