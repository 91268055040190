import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "./style.css";
import "../../App.css";
import Metainsights from "./Metainsights";
import Metamorph from "./Metamorph";

const Products = () => {
  const navigate = useNavigate();

  return (
    <>
      <h1 className="prod-h1" id="Products">
        Products
      </h1>
      <div className="prod">
        <div className="prod-content">
          <div className="prod-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="prod-container">
              <h3>MetAInsights</h3>
              <p>
                A unified 360° metadata tool, helps in transforming data
                discovery, streamline governance and collaboration to empower
                your team.
              </p>
            </div>
            {/* <Link to="/Metainsights">Learn More</Link> */}
            <p
              className="prod-btn"
              onClick={() => {
                // navigate("/Metainsights");
                // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                window.open("https://metainsights.gleafink.com", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>

        <div className="prod-content">
          <div className="prod-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="prod-container">
              <h3>DataSculpt</h3>
              <p>
                Unlock the power of your data with DataSculpt! Tailor your
                transformations to reveal insights that drive smart, strategic
                decisions.
              </p>
            </div>
            {/* <Link to="/Metamorph">Learn More</Link> */}
            <p
              className="prod-btn"
              onClick={() => {
                // navigate("/Metamorph");
                // window.open("/Metamorph", "_blank", "rel=noopener noreferrer");
                window.open("https://datasculpt.gleafink.com", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>

        <div className="prod-content">
          <div className="prod-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="prod-container">
              <h3>IntroSight</h3>
              <p>
                A semantic tool that transforms complex data into a
                user-friendly, enriched model, empowering business users to
                easily understand and leverage insights!
              </p>
            </div>
            {/* <Link to="/Metamorph">Learn More</Link> */}
            <p
              className="prod-btn"
              onClick={() => {
                // navigate("/Metamorph");
                // window.open("/Metamorph", "_blank", "rel=noopener noreferrer");
                window.open("https://introsight.gleafink.com", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>

        <div className="prod-content">
          <div className="prod-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="prod-container">
              <h3>Talk to Data</h3>
              <p>
                Experience the power of dialogue with your data! Jarvis AI tool
                transforms complex queries into clear insights, simplifying
                decision-making!
              </p>
            </div>
            {/* <Link to="/Metamorph">Learn More</Link> */}
            <p
              className="prod-btn"
              onClick={() => {
                // navigate("/Metamorph");
                // window.open("/Metamorph", "_blank", "rel=noopener noreferrer");
                window.open("https://jarvis.gleafink.com", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

/* <div className="prod" id="Products">
<div className="prod-content">
  <h1>PRODUCTS</h1>
  <Metainsights />
  <Metamorph />
</div>
</div> */
