import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../../App.css";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Connect = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [product, setProduct] = useState("Metainsights");
  const [message, setMessage] = useState("");

  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setSuccess(true);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name === "" || email === "" || company === "" || message === "") {
      alert("Please enter all the details");
    } else {
      console.log("Details:", name, email, company, product, message);
      navigate("/");
    }
  };
  return (
    <>
      <Navbar />
      <div className="connect">
        <div className="connect-content">
          <h1>Let's Connect</h1>
          <h3>We are your one stop technical partner:</h3>
          <form
            className="connect-form"
            name="contact"
            method="POST"
            action="/Connect/?success=true"
            data-netlify="true"
            netlify
          >
            <div>
              <p>Name: </p>
              <input
                type="text"
                placeholder="Pete Mitchell"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <p>Email:</p>
              <input
                type="text"
                placeholder="pete.mitchell@gleafink.com"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <p>Company: </p>
              <input
                type="text"
                placeholder="Gleafink Pvt Ltd"
                value={company}
                name="company"
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div>
              <p>Product:</p>
              <select
                value={product}
                name="product"
                onChange={(e) => setProduct(e.target.value)}
              >
                <option value="MetAInsights">MetAInsights</option>
                <option value="DataSculpt">DataSculpt</option>
                <option value="IntroSight">IntroSight</option>
                <option value="Talk to data">Talk to data</option>
              </select>
            </div>
            <div style={{ gridArea: "3 / 1 / span 6 / span 2" }}>
              <p>Message: </p>
              <input
                style={{ height: "60%" }}
                type="text"
                placeholder="Message"
                value={message}
                name="message"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button
              className="connect-btn"
              type="submit"
              onClick={handleSubmit}
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Connect;
