import React, { useState } from "react";
import "./style.css";
import "../../../App.css";

import NavbarMetainsights from "./NavbarMetainsights";
import FooterMetainsights from "./FooterMetainsights";
import { prodsample1, prodsample2, prodsample3 } from "../../../assets";

const Metainsights = () => {
  const [ov1, setOv1] = useState(true);
  const [ov2, setOv2] = useState(false);
  const [ov3, setOv3] = useState(false);
  const [ov4, setOv4] = useState(false);
  const [ov5, setOv5] = useState(false);

  return (
    <>
      <NavbarMetainsights />
      <section className="prod-ind-content">
        <div className="prod-points" style={{ flex: "0 0 60%" }}>
          <div className="prod-ind-sub">BRING CLARITY TO YOUR DATA</div>
          <h1>Take Back Control of Your Fragmented Data Stack</h1>
          {/* <h2 id="Metainsights">metainsights.gleafink.com</h2> */}
          <p>
            Metainsights is a comprehensive metadata platform that joins a
            best-in-class catalog with data observability. Built by the team of
            Gleafink.
          </p>
          <div className="prod-btn-line">
            <button
              className="prod-btn"
              onClick={() => {
                window.open("/Connect", "_blank");
              }}
            >
              Book a Demo
            </button>
            <p className="p-btn" style={{ margin: "15px 0 15px" }}>
              Explore Features{" "}
              <span style={{ color: "#1890ff" }}> &#x2192;</span>
            </p>
          </div>
        </div>
        <div className="prod-points displayN">
          <img
            src={prodsample1}
            alt="prodsample1"
            className="prod-sample-img"
          />
          {/* <p>
            <h3>Metadata Management:</h3>
            The tool allows organizations to capture and manage metadata,
            including data source details, schema information, data definitions,
            and business glossaries. It provides a holistic view of data assets
            and facilitates search and discovery of relevant data.
          </p>
          <p>
            <h3>Data Lineage Tracking:</h3>
            The tool enables tracking and visualization of data lineage,
            showcasing the journey of data from its source to various
            transformations and consumption points. It helps users understand
            data dependencies and impacts, ensuring transparency and trust in
            data usage.
          </p>
          <p>
            <h3>Data Catalog and Search:</h3>
            The tool offers a user-friendly catalog interface where users can
            search and explore available data assets based on different criteria
            such as keywords, attributes, and tags. It provides comprehensive
            data profiling and exploration capabilities to aid data discovery
            and understanding.
          </p>
          <p>
            <h3>Data Governance and Compliance:</h3>
            The tool supports data governance initiatives by enabling the
            definition and enforcement of data policies, access controls, and
            data privacy rules. It helps organizations maintain data quality,
            adhere to regulatory requirements, and manage data security and
            privacy risks.
          </p>
          <p>
            <h3>Collaboration and Data Stewardship:</h3>
            The tool facilitates collaboration and data stewardship by providing
            workflows, notifications, and task management capabilities. It
            allows data stewards and subject matter experts to contribute,
            validate, and curate metadata, ensuring its accuracy and relevance.
          </p>
          <p>
            <h3>Data Catalog API and Integration:</h3>
            The tool may offer APIs and integration capabilities to connect with
            various data sources, data integration platforms, and analytics
            tools. This enables seamless data ingestion, synchronization, and
            integration with other data management and analytics processes.
          </p>
          <p>
            <h3>Data Access and Usage Tracking:</h3>
            The tool helps monitor data access and usage patterns, providing
            visibility into who accessed the data, when, and for what purpose.
            It aids in auditing, compliance reporting, and ensuring data privacy
            and security.
          </p>
          <p>
            <h3>Data Quality Management:</h3>
            The tool provides features for data profiling, data quality
            assessment, and data cleansing. It helps identify data anomalies,
            inconsistencies, and errors, allowing organizations to improve data
            quality and reliability.
          </p>
          <p>
            <h3>Data Governance Reporting and Analytics:</h3>
            The tool offers reporting and analytics capabilities to track data
            governance metrics, monitor compliance, and measure the
            effectiveness of data governance initiatives. It provides insights
            and visualizations to support data-driven decision-making.
          </p>

          <p>
            Overall, Metainsights serves as a critical component of an
            organization's data management strategy, enabling efficient data
            discovery, understanding, and governance while ensuring data
            quality, compliance, and trustworthiness.
          </p> */}
        </div>
      </section>

      <section className="prod-ind-features">
        <div className="prod-points">
          <div className="prod-ind-sub" style={{ color: "#1890ff" }}>
            WHY METAINSIGHTS
          </div>
          <h1>
            The Unified Approach to Data Discovery, Governance, and
            Observability
          </h1>
          <div className="prod-card-container">
            <div className="prod-ind-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="prod-ind-container">
                <h3>Designed for Catalog Adoption</h3>
                <p>
                  No more data catalog ghost towns. Metainsights drives fast
                  time-to-value via Shift Left practices for data producers and
                  an intuitive UI for data consumers.
                </p>
              </div>
              {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
            <div className="prod-ind-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="prod-ind-container">
                <h3>Ensure Trust with Observability</h3>
                <p>
                  Continuously detect data quality incidents in real-time,
                  automate anomaly detection to prevent breakages, and drive
                  fast resolution when they do occur.
                </p>
              </div>
              {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
            <div className="prod-ind-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="prod-ind-container">
                <h3>Built on Event-Driven Metadata</h3>
                <p>
                  Metainsights supports both push-based and pull-based metadata
                  ingestion for easy maintenance, ensuring information is
                  trustworthy, up-to-date, and definitive.
                </p>
              </div>
              {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
            <div className="prod-ind-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="prod-ind-container">
                <h3>Don’t Just Document - Take Action</h3>
                <p>
                  Data should be operational. Go beyond simple visibility and
                  use automated Metadata Tests to continuously expose data
                  insights and surface new areas for improvement.
                </p>
              </div>
              {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
            <div className="prod-ind-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="prod-ind-container">
                <h3>Rapid Incident Resolution</h3>
                <p>
                  Reduce confusion and accelerate resolution with clear asset
                  ownership, automatic detection, streamlined alerts, and
                  time-based lineage for tracing root causes.
                </p>
              </div>
              {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
            <div className="prod-ind-card">
              {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
              <div className="prod-ind-container">
                <h3>Open Core for Developers</h3>
                <p>
                  Build with the most flexible SDK and comprehensive APIs for
                  development. Configure Metainsights to meet the needs of your
                  most advanced use cases.
                </p>
              </div>
              {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <section className="prod-ind-news">
        <div className="prod-points" style={{ flex: "0 0 40%" }}>
          <div className="prod-ind-sub" style={{ color: "#1890ff" }}>
            PRODUCT NEWS
          </div>
          <h1>Announcing Metainsights Observe</h1>
          <p style={{ textAlign: "initial" }}>
            Driven by a central metadata control plane, Metainsights Observe
            continuously detects data quality incidents in real-time. Automate
            anomaly detection to prevent breakages and drive faster resolution
            when they do occur.
          </p>
          <div className="prod-btn-line">
            <p className="p-btn" style={{ margin: "15px 0 15px" }}>
              Learn more about{" "}
              <span style={{ color: "#1890ff" }}> Metainsights &#x2192;</span>
            </p>
          </div>
        </div>
        <div className="prod-points">
          <img
            src={prodsample2}
            alt="prodsample1"
            className="prod-sample-img"
            // style={{ height: "85%" }}
          />
        </div>
      </section>

      <section className="prod-ind-overview">
        <div className="prod-points">
          <div className="prod-ind-sub" style={{ color: "#1890ff" }}>
            PRODUCT OVERVIEW
          </div>
          <h1 style={{ margin: "15px 0 30px" }}>
            Managed Metadata and Much More
          </h1>
          {/* <img
            src={prodsample3}
            alt="prodsample1"
            className="prod-sample-img"
            style={{ height: "50%", width: "60%" }}
          /> */}
          <div className="prod-ind-big-card">
            {ov1 && (
              <img
                src={prodsample3}
                alt="prodsample3"
                style={{ width: "60%", transition: "0.3s" }}
              />
            )}
            {ov2 && (
              <img
                src={prodsample3}
                alt="prodsample3"
                style={{ width: "60%", transition: "0.3s" }}
              />
            )}
            {ov3 && (
              <img
                src={prodsample3}
                alt="prodsample3"
                style={{ width: "60%", transition: "0.3s" }}
              />
            )}
            {ov4 && (
              <img
                src={prodsample3}
                alt="prodsample3"
                style={{ width: "60%", transition: "0.3s" }}
              />
            )}
            {ov5 && (
              <img
                src={prodsample3}
                alt="prodsample3"
                style={{ width: "60%", transition: "0.3s" }}
              />
            )}
            <div className="prod-ind-big-container">
              <div
                className={ov1 && "border-purple"}
                onClick={() => {
                  setOv1(true);
                  setOv2(false);
                  setOv3(false);
                  setOv4(false);
                  setOv5(false);
                }}
              >
                <p>Operational Data Catalog</p>
                {ov1 && (
                  <p style={{ margin: "0" }}>
                    A central resource to connect technical and non-technical
                    teams, featuring unified search and full visibility into
                    asset documentation and ownership.
                  </p>
                )}
              </div>
              <div
                className={ov2 && "border-purple"}
                onClick={() => {
                  setOv2(true);
                  setOv1(false);
                  setOv3(false);
                  setOv4(false);
                  setOv5(false);
                }}
              >
                <p>Lineage and Impact Analysis</p>
                {ov2 && (
                  <p style={{ margin: "0" }}>
                    Quickly understand the end-to-end journey of data and
                    proactively identify potential breaking changes by tracing
                    data lineage.
                  </p>
                )}
              </div>
              <div
                className={ov3 && "border-purple"}
                onClick={() => {
                  setOv3(true);
                  setOv1(false);
                  setOv2(false);
                  setOv4(false);
                  setOv5(false);
                }}
              >
                <p>Automated Data Governance</p>
                {ov3 && (
                  <p style={{ margin: "0" }}>
                    Metadata Tests allow you to define and continuously evaluate
                    a set of conditions on the most important data assets in
                    your company.
                  </p>
                )}
              </div>
              <div
                className={ov4 && "border-purple"}
                onClick={() => {
                  setOv4(true);
                  setOv1(false);
                  setOv2(false);
                  setOv3(false);
                  setOv5(false);
                }}
              >
                <p>Active Business Glossary</p>
                {ov4 && (
                  <p style={{ margin: "0" }}>
                    Easily manage glossary terms and tags with an intuitive UI.
                    Configure approval workflows to incorporate feedback from
                    business teams.
                  </p>
                )}
              </div>
              <div
                className={ov5 && "border-purple"}
                onClick={() => {
                  setOv5(true);
                  setOv1(false);
                  setOv2(false);
                  setOv3(false);
                  setOv4(false);
                }}
              >
                <p>Integrated Data Observability</p>
                {ov5 && (
                  <p style={{ margin: "0" }}>
                    Monitor the health of datasets and pipelines in real-time,
                    with automated and user-defined assertions for data quality.
                  </p>
                )}
              </div>
            </div>
            {/* <p
                className="prod-ind-btn"
                onClick={() => {
                  window.open("/Metainsights", "_blank");
                }}
              >
                Learn More
              </p> */}
          </div>
          <p style={{ margin: "30px 0" }}>
            Metainsights offers a comprehensive data catalog at its core and
            brings business and data teams together with advanced features.
            Connect data sources within minutes and start using the control
            plane for your data stack.
          </p>
          {/* <div className="prod-btn-line"> */}
          <button
            className="prod-btn"
            style={{
              background: "#222222",
              color: "#fafafa",
              border: "3px solid #222222",
              float: "none",
            }}
            onClick={() => {
              window.open("/", "_blank");
            }}
          >
            Get a Product Overview
          </button>
        </div>
        {/* </div> */}
      </section>
      <FooterMetainsights />
    </>
  );
};

export default Metainsights;
