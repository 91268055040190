import React from "react";
import "./style.css";
import "../../App.css";
import Navbar from "../Navbar";
import Footer from "../Footer";

import VisionMisson from "../VisionMission";
import CoreValues from "../CoreValues";
import Services from "../Services";
import Products from "../Products";
import { pointer, websiteimg1, websiteimg2, websiteimg3 } from "../../assets";
import Testimonials from "../Testimonials";
import Featured from "../Featured";
import WeValue from "../WeValue";
import About from "../About";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="home" id="Home">
        <div className="landing">
          <div className="home-content">
            <h1>We Action(able) Insights by Transforming Data</h1>
            <p>
              <p>
                A leading data & advanced analytics company. We empower
                businesses to make data-driven decisions for a smarter future.
              </p>
              <div className="home-btn-line">
                <button
                  className="home-btn"
                  onClick={() => {
                    window.open("/Connect", "_blank");
                  }}
                >
                  Get in touch
                </button>
                {/* <p className="h-btn" style={{ margin: "15px 0 15px" }}>
                Explore Features{" "}
                <span style={{ color: "#1890ff" }}> &#x2192;</span>
              </p> */}
              </div>
              {/* <div className="home-points" id="About">
              <h3>
                Our unique <strong>data differentiators</strong> are designed
                to:
              </h3>
              <div>
                <p>
                  <img
                    src={pointer}
                    alt="Pointer icon"
                    className="pointer-icon"
                  />
                  Deliver cutting-edge data analytics solutions
                </p>
                <p>
                  <img
                    src={pointer}
                    alt="Pointer icon"
                    className="pointer-icon"
                  />
                  Derive near real-time actionable insights
                </p>
                <p>
                  <img
                    src={pointer}
                    alt="Pointer icon"
                    className="pointer-icon"
                  />
                  Discover innovative solutions to business problems
                </p>
                <p>
                  <img
                    src={pointer}
                    alt="Pointer icon"
                    className="pointer-icon"
                  />
                  Drive informed decision making and Realize Digital Capital
                </p>
              </div>
            </div> */}
            </p>
          </div>
          {/* <div className="home-img1">
          <div className="rhombus1">
            <VisionMisson />
          </div>
        </div> */}
          {/* <div className="rhombus2"></div> */}
        </div>

        <CoreValues />

        {/* <div className="service"> */}
        {/* <div className="home-img3">
            <div className="rhombus3">
              <img src={websiteimg2} alt="home-image" />
            </div>
            <div className="rhombus5">
              <img src={websiteimg1} alt="home-image" />
            </div>
            <div className="rhombus4">
              <img src={websiteimg3} alt="home-image" />
            </div>
          </div> */}
        {/* <div className="home-content"> */}
        <Services />
        {/* </div> */}
        {/* </div> */}
        <WeValue />
        <About />
        <Products />
        {/* <Featured /> */}
        {/* <Testimonials /> */}
      </div>
      <Footer />
    </>
  );
};

export default HomePage;
