import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../../App.css";
import { facebook, instagram, logoSmall, twitter } from "../../assets";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tandc, setTandc] = useState(false);

  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.search.includes("success=true")) {
      setSuccess(true);
    }
  }, []);

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (username == "" || password == "") {
  //     alert("Please enter all the details");
  //   } else {
  //     console.log("Details:", username);
  //     navigate("/");
  //   }
  // };

  const url = "https://www.mecallapi.com/api/login";
  const url1 = "";

  async function loginUser(credentials) {
    return fetch(url1, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    }).then((data) => data.json());
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (username == "" || password == "") {
      alert("Please enter all the details");
    } else {
      const response = await loginUser({ username, password });
      if ("accessToken" in response) {
        console
          .log("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
            localStorage.setItem("accessToken", response["accessToken"]);
            localStorage.setItem("user", JSON.stringify(response["user"]));
            // window.location.href = "/profile";
          });
      } else {
        console.log("Failed", response.message, "error");
      }
    }
  };

  return (
    <>
      <div className="login-wrap">
        <div className="login">
          <div className="welcome-content">
            <img className="login-logo" src={logoSmall} alt="logo_small" />
            <h1>Welcome</h1>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla
              reiciendis harum, hic eligendi expedita in assumenda, non dicta
              nobis commodi natus praesentium quos illo? Reiciendis id iure
              fugit repellat. Soluta?
            </p>
            <button className="welcome-btn" type="submit">
              Learn more
            </button>
          </div>
          <div className="login-content">
            <div className="login-card">
              <div className="login-container">
                <h3>Log in to dashboard</h3>
                <p>
                  Don't have an account? <span>Sign-up</span>
                </p>
                <form
                  className="login-form"
                  name="contact"
                  method="POST"
                  action="/Login/?success=true"
                  data-netlify="true"
                  netlify
                >
                  <div>
                    <p className="pw-row">* Username:</p>
                    <input
                      type="text"
                      placeholder="Username"
                      value={username}
                      name="email"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div>
                    <p className="pw-row">
                      <p>* Password:</p>
                      <p
                        style={{
                          fontSize: "14px",
                          textDecoration: "underline",
                        }}
                      >
                        Reset Password
                      </p>
                    </p>
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      style={{ width: "auto" }}
                      type="checkbox"
                      value={tandc}
                      name="checkbox"
                      onChange={(e) => setTandc(!tandc)}
                    />
                    <p style={{ margin: "0" }}>
                      I agree to all{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Terms and Conditions
                      </span>{" "}
                    </p>
                  </div>
                  <button
                    className="login-btn"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </form>
                <ul className="login-social-links">
                  <li>
                    <a className="login-footer-link" href="#">
                      <img
                        src={instagram}
                        alt="instagram icon"
                        className="login-social-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a className="login-footer-link" href="#">
                      <img
                        src={facebook}
                        alt="facebook icon"
                        className="login-social-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a className="login-footer-link" href="#">
                      <img
                        src={twitter}
                        alt="twitter icon"
                        className="login-social-icon"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
