import React from "react";
import "./style.css";
import "../../App.css";

const Services = () => {
  return (
    <>
      <h1 className="serv-h1" id="Services">
        Services
      </h1>
      <div className="serv">
        {/* <div className="serv-content">
        <h1>Services</h1>
        <p>
          Gleafink offer a range of services focused on developing,
          implementing, and supporting data-driven solutions. Some common
          services provided by data product companies include:
        </p>
        <div className="core-points">
          <p>
            <h3>Data Product Development:</h3>
            Gleafink specialize in designing and developing data products
            tailored to their clients' specific needs. This involves leveraging
            data analytics, machine learning, and artificial intelligence to
            extract insights from data and deliver actionable outcomes.
          </p>
          <p>
            <h3>Data Strategy and Consulting:</h3>
            Data product companies help businesses define their data strategy,
            providing guidance on data governance, infrastructure, and best
            practices. They assist in identifying data sources, determining key
            metrics, and establishing data management frameworks.
          </p>
          <p>
            <h3>Data Integration and ELT (Extract, Load, Transform):</h3>
            Gleafink assist in integrating and consolidating data from various
            sources, ensuring data quality, and transforming it into a usable
            format. They develop ETL pipelines and implement data integration
            solutions to enable smooth data flow across systems.
          </p>
          <p>
            <h3>Data Visualization and Reporting:</h3>
            Gleafink offer services in creating interactive data visualizations
            and intuitive dashboards to present insights in a visually appealing
            and user-friendly manner. They focus on delivering reports and
            visual representations that enable stakeholders to interpret data
            effectively.
          </p>
          <p>
            <h3>Predictive Analytics and Machine Learning:</h3>
            Gleafink employs advanced analytics techniques, including predictive
            modelling and machine learning, to uncover patterns, make
            predictions, and derive actionable insights. They build models and
            algorithms to support forecasting, anomaly detection, customer
            segmentation, and other predictive use cases.
          </p>
          <p>
            <h3>Data Governance and Compliance:</h3>
            Gleafink assist businesses in establishing data governance
            frameworks, policies, and procedures. They ensure compliance with
            data protection regulations and industry standards, implementing
            data security measures, privacy controls, and data access management
            protocols.
          </p>
          <p>
            <h3>Data Infrastructure and Cloud Services:</h3>
            Gleafink help clients set up scalable and robust data infrastructure
            by leveraging cloud platforms and services. They provide expertise
            in cloud migration, data storage, data lakes, and data warehousing
            solutions to support data product development and deployment.
          </p>
          <p>
            <h3>Training and Support:</h3>
            Gleafink offer training and support services to ensure their clients
            can effectively utilize and maintain data products. They provide
            user training, technical support, and ongoing maintenance to address
            any issues and ensure smooth operation of the implemented solutions.
          </p>
          <p>
            <h3> Data Analytics and Insights:</h3>
            Gleafink offer data analytics services to extract meaningful
            insights from raw data. They use statistical analysis, data mining,
            and exploratory data analysis techniques to identify patterns,
            trends, and correlations, helping businesses make informed
            decisions.
          </p>
          <p>
            <h3>Data Product Management:</h3>
            Gleafink provide end-to-end data product management services, taking
            care of the entire lifecycle of data products. They handle ideation,
            development, implementation, deployment, and continuous improvement,
            aligning data products with business goals and objectives.
          </p>
        </div>
        <p>
          It's important to note that the specific services offered by data
          product companies may vary based on their expertise, focus areas, and
          client requirements.
        </p>
      </div> */}
        <div className="serv-content">
          <div className="serv-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="serv-container">
              <h3>Data Strategy & Roadmap</h3>
              <p>
                Our data strategy specialists assist in crafting a resilient
                data management vision that harmonizes with fundamental business
                goals.
              </p>
            </div>
            <p
              className="serv-btn"
              onClick={() => {
                // navigate("/Metainsights");
                // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                window.open("/Metainsights", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>
        <div className="serv-content">
          <div className="serv-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="serv-container">
              <h3>Data & Analytics Modernization</h3>
              <p>
                Embrace Data Transformation: Elevate from Legacy to a
                Cloud-Powered Modern Data Stack with Data Lakes, Warehouses, and
                Lakehouses
              </p>
            </div>
            {/* <Link to="/Metainsights">Learn More</Link> */}
            <p
              className="serv-btn"
              onClick={() => {
                // navigate("/Metainsights");
                // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                window.open("/DataEnggAna", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>
        <div className="serv-content">
          <div className="serv-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="serv-container">
              <h3>Machine Learning & AI</h3>
              <p>
                Unlock Potential with AI/ML: Shape Predictive Models, Forge
                Cutting-Edge AI Apps, and Ignite Creativity with Generative AI
              </p>
            </div>
            {/* <Link to="/Metainsights">Learn More</Link> */}
            <p
              className="serv-btn"
              onClick={() => {
                // navigate("/Metainsights");
                // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                window.open("/Metainsights", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>
        <div className="serv-content">
          <div className="serv-card">
            {/* <img src="img_avatar.png" alt="Avatar" style={{ width: "100%" }} /> */}
            <div className="serv-container">
              <h3>Data Monetization</h3>
              <p>
                Turning Data into Gold: A Vital Element in the Playbooks of Top
                Performers and Rapid-Growth Enterprises
              </p>
            </div>
            {/* <Link to="/Metainsights">Learn More</Link> */}
            <p
              className="serv-btn"
              onClick={() => {
                // navigate("/Metainsights");
                // window.open("/Metainsights", "_blank", "rel=noopener noreferrer");
                window.open("/Metainsights", "_blank");
              }}
            >
              Learn More
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
