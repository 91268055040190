import React from "react";
import "./style.css";
import "../../App.css";
import {
  CollaborationImage,
  ContinuousLearning,
  ExcellenceImage,
  InnovationImage,
} from "../../assets";

const CoreValues = () => {
  return (
    <div className="core">
      <div className="core-content">
        <h1>Our core values:</h1>
        <div className="core-points">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4rem",
              margin: "2rem 0",
            }}
          >
            <img
              src={InnovationImage}
              alt="Innovation Inage"
              style={{
                width: "16rem",
                height: "16rem",
                // borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <p>
              <h3>Innovation:</h3>
              We strive to push the boundaries of data product development,
              embracing emerging technologies and methodologies to deliver
              innovative solutions that meet the evolving needs of our clients.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4rem",
              margin: "2rem 0",
            }}
          >
            <p>
              <h3>Excellence:</h3>
              We are committed to delivering excellence in every aspect of our
              work. We prioritize quality, accuracy, and attention to detail to
              ensure that our data products exceed expectations and deliver
              tangible value to our clients.
            </p>
            <img
              src={ExcellenceImage}
              alt="Innovation Inage"
              style={{
                width: "16rem",
                height: "16rem",
                // borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4rem",
              margin: "2rem 0",
            }}
          >
            <img
              src={CollaborationImage}
              alt="Innovation Inage"
              style={{
                width: "16rem",
                height: "16rem",
                // borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <p>
              <h3>Collaboration:</h3>
              We foster a culture of collaboration, both within our team and
              with our clients. We believe in close partnerships and open
              communication to understand our clients' challenges, co-create
              solutions, and continuously improve our products based on valuable
              feedback.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4rem",
              margin: "2rem 0",
            }}
          >
            <p>
              <h3>Customer-Centricity:</h3>
              Our customers are at the heart of everything we do. We listen to
              their needs, understand their objectives, and tailor our data
              products to address their specific challenges. We are dedicated to
              providing exceptional customer experiences and building long-term
              relationships based on trust and mutual success.
            </p>
            <img
              src={InnovationImage}
              alt="Innovation Inage"
              style={{
                width: "16rem",
                height: "16rem",
                // borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4rem",
              margin: "2rem 0",
            }}
          >
            <img
              src={InnovationImage}
              alt="Innovation Inage"
              style={{
                width: "16rem",
                height: "16rem",
                // borderRadius: "50%",
                objectFit: "cover",
              }}
            />
            <p>
              <h3>Ethical Data Practices:</h3>
              We prioritize ethical data handling and privacy. We ensure that
              our data products adhere to relevant data protection regulations
              and industry standards, safeguarding sensitive information and
              maintaining the highest level of data security and
              confidentiality.
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "4rem",
              margin: "2rem 0",
            }}
          >
            <p>
              <h3>Continuous Learning:</h3>
              We foster a culture of continuous learning and growth, encouraging
              our team to stay at the forefront of industry trends,
              technologies, and best practices. We invest in the professional
              development of our employees to ensure they have the skills and
              knowledge required to deliver top-tier data products.
            </p>
            <img
              src={ContinuousLearning}
              alt="Innovation Inage"
              style={{
                width: "16rem",
                height: "16rem",
                // borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </div>
          <p>
            Through our vision, mission, and core values, we aim to make a
            significant impact in the data product development space, enabling
            businesses to leverage data-driven insights to drive innovation,
            efficiency, and success in their operations.
          </p>
        </div>
      </div>

      {/* <div className="core-img">
        <div className="rhombus">
          <img src="" alt="home-image" />
          </div>
      </div>
      <div className="rhombus2"></div> */}
    </div>
  );
};

export default CoreValues;
