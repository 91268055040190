import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import Connect from './components/Connect'
import Metainsights from './components/Products/Metainsights';
import Metamorph from './components/Products/Metamorph';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import DataEnggAna from './components/DataEnggAna';
import UnderConstruction from './components/UnderConstruction';

function App() {

  // const token = localStorage.getItem('accessToken');

  // if (!token) {
  //   return <Signin />
  // }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          {/* <Route index element={<HomePage />} /> */}
          <Route exact path="/Metainsights" element={<Metainsights />} />
          {/* <Route exact path="/Metamorph" element={<Metamorph />} /> */}
          <Route exact path="/Connect" element={<Connect />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Dashboard" element={<Dashboard />} />
          <Route exact path="/DataEnggAna" element={<DataEnggAna />} />
          <Route exact path="*" element={<UnderConstruction />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


{/* <Navbar />
    <HomePage />
    <Footer /> */}