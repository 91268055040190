import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../../App.css";
import { logo_name } from "../../assets";
// import { useWindowDimensions } from "../Common/useWindowDimensions";

const Navbar = () => {
  const navigate = useNavigate();
  // const { height, width } = useWindowDimensions();

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [show, setShow] = useState(true);
  // const [lastScrollY, setLastScrollY] = useState(0);

  const [aboutNav, setAboutNav] = useState(false);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  // const controlNavbar = () => {
  //   if (typeof window !== "undefined") {
  //     if (window.scrollY > lastScrollY) {
  //       // if scroll down hide the navbar
  //       setShow(false);
  //     } else {
  //       // if scroll up show the navbar
  //       setShow(true);
  //     }

  //     // remember current page location to use in the next move
  //     setLastScrollY(window.scrollY);
  //   }
  // };

  // Smooth scrolling animation
  // const allLinks = document.querySelectorAll("a:link");
  // console.log(allLinks);
  // allLinks.forEach((link) => {
  //   link.addEventListener("click", (e) => {
  //     e.preventDefault();
  //     const href = link.getAttribute("href");
  //     console.log(href);

  //     // scroll back to top
  //     if (href == "#")
  //       window.scrollTo({
  //         top: 0,
  //         behavior: "smooth",
  //       });
  //     // scroll to other links
  //     if (href !== "#" && href.startsWith("#")) {
  //       const sectionEl = document.querySelector(href);
  //       sectionEl.scrollIntoView({ behavior: "smooth" });
  //     }
  //   });
  // });

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    // if (typeof window !== "undefined") {
    //   window.addEventListener("scroll", controlNavbar);

    //   // cleanup function
    //   return () => {
    //     window.removeEventListener("scroll", controlNavbar);
    //   };
    // }

    return () => {
      window.removeEventListener("resize", updateDimension);
      console.log("width", screenSize.width);
    };
    // }, [screenSize, lastScrollY]);
  }, [screenSize]);

  useEffect(() => {
    let url = window.location.href.split("/");
    let target = url[url.length - 1].toLowerCase();
    let element = document.getElementById(target);
    element && element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <>
      {show && (
        <div className="nav-container">
          <img
            src={logo_name}
            alt={"Logo"}
            className="logo"
            onClick={() => {
              navigate("/");
            }}
          />
          {screenSize.width > 385 && (
            <nav className="nav">
              <a
                href="/"
                className="active"
                onClick={(e) => {
                  let home = document.getElementById("Home");
                  e.preventDefault();
                  home &&
                    home.scrollIntoView({ behavior: "smooth", block: "start" });
                  window.history.pushState("home", "Home", "/Home");
                }}
              >
                Home
              </a>
              <div className="dropdown">
                <a
                  className="dropbtn"
                  href="/"
                  onClick={(e) => {
                    let about = document.getElementById("About");
                    e.preventDefault();
                    about &&
                      about.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    window.history.pushState("about", "About", "/About");
                  }}
                  onMouseEnter={() => setAboutNav(true)}
                  onMouseLeave={() =>
                    setTimeout(() => {
                      setAboutNav(false);
                    }, 2400)
                  }
                >
                  About
                </a>
              </div>

              <div className="dropdown">
                <a
                  className="dropbtn"
                  href="/"
                  onClick={(e) => {
                    let services = document.getElementById("Services");
                    e.preventDefault();
                    services &&
                      services.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    window.history.pushState(
                      "services",
                      "Services",
                      "/Services"
                    );
                  }}
                >
                  Services
                </a>
                <div className="dropdown-content-serv">
                  <a href="/#Services" target="_blank">
                    Data Strategy & Roadmap
                  </a>
                  <a href="/DataEnggAna" target="_blank">
                    Data & Analytics Modernization
                  </a>
                  <a href="/#Services" target="_blank">
                    Data Engineering
                  </a>
                  <a href="/#Services" target="_blank">
                    Machine Learning & AI
                  </a>
                  <a href="/#Services" target="_blank">
                    Data Monetization
                  </a>
                </div>
              </div>
              <div className="dropdown">
                <a
                  className="dropbtn"
                  href="/"
                  onClick={(e) => {
                    let products = document.getElementById("Products");
                    e.preventDefault();
                    products &&
                      products.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    window.history.pushState(
                      "products",
                      "Products",
                      "/Products"
                    );
                  }}
                >
                  Products
                </a>
                <div className="dropdown-content">
                  <a href="/Metainsights" target="_blank">
                    Metainsights
                  </a>
                  <a href="/Metamorph" target="_blank">
                    DataSculpt
                  </a>
                  <a href="/Metamorph" target="_blank">
                    IntroSight
                  </a>
                  <a href="/Metamorph" target="_blank">
                    Talk to Data
                  </a>
                </div>
              </div>
              {/* <a
                href="/"
                onClick={(e) => {
                  let weValue = document.getElementById("WeValue");
                  e.preventDefault();
                  weValue &&
                    weValue.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  window.history.pushState("weValue", "WeValue", "/WeValue");
                }}
              >
                Why Gleafink
              </a> */}
              <a href="/#Labs">Gleafink Labs</a>
              <a href="/#Insights">Insights</a>
              <a href="/Connect" target="_blank">
                Lets Connect
              </a>
            </nav>
          )}

          {screenSize.width <= 385 && <nav className="nav"></nav>}
        </div>
      )}

      {aboutNav && (
        <div className="nav-container-sec">
          <nav className="nav" style={{ marginLeft: "14rem" }}>
            <a
              href="/"
              onClick={(e) => {
                let about = document.getElementById("About");
                e.preventDefault();
                about &&
                  about.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                window.history.pushState("about", "About", "/About");
              }}
            >
              About Us
            </a>
            <a href="/Metamorph" target="_blank">
              Team
            </a>
            <a href="/Metamorph" target="_blank">
              Clients
            </a>
            <a
              href="/"
              onClick={(e) => {
                let weValue = document.getElementById("WeValue");
                e.preventDefault();
                weValue &&
                  weValue.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                window.history.pushState("weValue", "WeValue", "/WeValue");
              }}
            >
              Why Gleafink
            </a>
          </nav>
        </div>
      )}
    </>
  );
};

export default Navbar;
